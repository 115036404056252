(function(CSS) {
  "use strict";
  if (!CSS || !CSS.supports("color", "var(--env-color-brand)")) {
    import('css-vars-ponyfill').then(({ default: cssVars }) => {
      cssVars({
        silent: true,
        watch: true,
        exclude: '[href^="data:text"]'
     });
    })
  }
})(window.CSS);